<template>
    <div>

        <Toast position="bottom-right" />

        <!-- change password dialog  -->
        <teleport to="body">
            <base-dialog :top="'20'" :width="'30'" :open="show_change_password_dialog" @close="closeChangePasswordDialog()">
                <h3 style="color: var(--font1)"> Change password </h3>
                <div class="wwd-column change-password-dialog">
                    <div>
                        <label class="label-font dialog-label" for="username1">Current password</label>
                        <it-input autocomplete="new-password" v-model="current_password" class="connect-input p-inputtext-sm" type="password" /> 
                    </div>
                    <div>
                        <label class="label-font dialog-label" for="username1">New password</label>
                        <it-input autocomplete="new-password" v-model="new_pasword" class="connect-input p-inputtext-sm" type="password" /> 
                    </div>
                    <div>
                        <label class="label-font dialog-label" for="username1">Confirm new password</label>
                        <it-input autocomplete="new-password" v-model="new_password_repeat" class="connect-input p-inputtext-sm" type="password" /> 
                    </div>
                </div>
                
                <div class="error-message"> {{ error_message }} </div>

                <div class="dialog-button-wrapper">
                    <Button @click="closeChangePasswordDialog()" class="p-button p-button-secondary p-button-outlined" label="Cancel" />
                    <Button @click="changePassword()" :loading="changing_password" class="p-button p-button-success" label="Proceed" icon="pi pi-check" iconPos="right" 
                    :disabled="!(new_pasword && new_password_repeat)"/>
                </div>

            </base-dialog>
        </teleport>
        <!-- change password dialog  -->


        <Card class="userinfo-card base-card font-sans">
            <template #title>
                Account
            </template>
            <template #content>
                <div class="wwd-column userinfo-wrapper">
                    <div class="form-container">
                        <label class="label-font" for="username1">E-mail</label>
                        <div class="control-form-wrapper">
                            <div class="input-wrapper">
                                <it-input disabled v-model="user.mail" class="connect-input p-inputtext-sm" type="text" /> 
                            </div>
                            <!-- <i class="pi pi-pencil control-icon" v-tooltip.left.click="'Change email'"></i> -->
                        </div>
                    </div>
                    <div class="form-container">
                        <label class="label-font" for="username1">Password</label>
                        <div class="control-form-wrapper">
                            <div class="input-wrapper">
                                <it-input disabled v-model="user.password" class="connect-input p-inputtext-sm" type="text" /> 
                            </div>
                            <i @click="openChangePasswordDialog()" class="pi pi-pencil control-icon" v-tooltip.left.click="'Change password'"></i>
                        </div>
                    </div>
                    <!-- <div class="form-container">
                        <label class="label-font" for="username1">Phone</label>
                        <div class="control-form-wrapper">
                            <div class="input-wrapper">
                                <it-input disabled v-model="user.phone_number" class="connect-input p-inputtext-sm" type="text" /> 
                            </div>
                            <i @click="copyToClipBoard($store.getters.vmIp)" class="pi pi-copy control-icon" v-tooltip.top.click="'Copy to clipboard'"></i>
                        </div>
                    </div> -->
                </div>
            </template>
            <template #footer>
                <div>
                    <Button @click="logOut()" icon="pi pi-sign-out" label="Logout" class="p-button-secondary p-button-outlined" />
                </div>
            </template>
        </Card>    
            </div>
</template>

<script>
    import Card from 'primevue/card';
    import Button from 'primevue/button';
    import Toast from 'primevue/toast';
    import BaseDialog from '@/components/ui_items/BaseDialog.vue'
    export default {
        components: {
            Card,
            Button,
            BaseDialog,
            Toast
        },
        data() {
            return {
                user: {
                    mail: null,
                    password: '********',
                    uid: null,
                    phone_number: null,
                    email_verified: null
                },
                show_change_password_dialog: false,
                current_password: null,
                new_pasword: null,
                new_password_repeat: null,
                changing_password: false,
                error_message: null
            }
        },
        methods: {
            logOut() {
                this.$store.dispatch('logOut')
            },
            setUserData() {
                this.user.mail = this.$store.getters.user.data.email
                this.user.uid = this.$store.getters.user.data.uid
                this.user.phone_number = this.$store.getters.user.data.phone_number,
                this.user.email_verified = this.$store.getters.user.data.emailVerified
            },
            openChangePasswordDialog() {   
                this.show_change_password_dialog = true
            },
            closeChangePasswordDialog() {
                this.show_change_password_dialog = false
                this.current_password = null,
                this.new_pasword = null,
                this.new_password_repeat = null
            },
            async changePassword() {
                this.changing_password = true
                if (this.new_pasword !== this.new_password_repeat) {
                    this.error_message = 'The passwords given are different'
                    this.changing_password = false
                    return
                }
                try {
                    await this.$store.dispatch('updatePassword', {
                        current_password: this.current_password,
                        new_password: this.new_pasword
                    })
                    this.changing_password = false
                    this.closeChangePasswordDialog()
                    this.$toast.add({severity:'success', summary: 'Success!', detail: `Password changed correctly`, life: 3000}); 
                } catch(err) {
                    this.error_message = err.message
                    this.changing_password = false
                }
            }
        },
        mounted() {
            this.setUserData()
        },
    }
</script>

<style scoped>
.userinfo-card {
    height: 31rem !important;
}
.userinfo-card:deep(.p-card-content) {
    min-height: 22rem;
}
.userinfo-card:deep(.p-card-title) {
    display: flex;
    align-items: center;
    font-weight: 400;
}
.userinfo-card:deep(.p-card-footer) {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.userinfo-wrapper {
    gap: 0.1rem;
}
.userinfo-wrapper > * {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    width: 100%;
}
.userinfo-icon {
    font-size: 1.7rem;
    color: var(--green2);
}
::v-deep(.it-input-wrapper--disabled) {
    border-color: var(--color10) !important;
}

.form-container {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
}
.control-form-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 0.5rem;
}
.input-wrapper {
    width: 90%;
}
.control-icon {
    font-size: 1.2rem;
}
.control-icon:hover {
    cursor: pointer;
}
.change-password-dialog {
    color: var(--font1);
    gap: 0.5rem;
}
.dialog-label {
    font-size: 0.8rem !important;
}
.dialog-button-wrapper {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    justify-content: flex-end;
    margin-top: 2rem;
}
.error-message {
    font-size: 0.8rem;
    color: var(--font2);
    margin-top: 0.5rem;
}
</style>