<template>
    <div class="container">
        <UserAccount class="user-account"></UserAccount>
        <PreferencesCard class="preferences"></PreferencesCard>
        <LicenseInfo class="user-account"></LicenseInfo>
    </div>
    <div class="container">
    </div>
</template>
<script>

import UserAccount from '../components/settings/UserAccount.vue';
import LicenseInfo from '../components/settings/LicenseInfo.vue';
import PreferencesCard from '../components/settings/PreferencesCard.vue'
export default {
    components: {
        UserAccount,
        LicenseInfo,
        PreferencesCard
    }
}
</script>

<style scoped>
.container {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 0.2rem;
}
.user-account {
    flex: 1 30%;
}
.preferences {
    width: 30%;
}

@media (max-width: 800px) {
    .container {
        flex-direction: column;
    }
    .user-account {
        flex: 1 100%;
    }
}
</style>