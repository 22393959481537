<template>
    <div>
        <Card class="userinfo-card base-card font-sans">
            <template #title>
                Preferences
            </template>
            <template #content>
                <div class="preferences-container">
                    <div class="preference">
                        <it-switch v-model="preferences.advanced_mode" class="it-switch" type="warning" label="Advanced mode" />
                    </div>
                    <div class="preference">
                        <it-switch v-model="preferences.show_preview" class="it-switch" type="warning" label="Open sequencer preview on sequence start" />
                    </div>
                    <div class="preference">
                        <it-switch v-model="preferences.show_setup_dialog" class="it-switch" type="warning" label="Display the setup sequence dialog after the model starts" />
                    </div>
                </div>
            </template>
            <template #footer>
                <Button :loading="saving" label="Save preferences" @click="setPreferences()" class="p-button p-button-secondary"></Button>
            </template>

        </Card>    
    </div>
</template>

<script>
    import Card from 'primevue/card';
    import Button from 'primevue/button'
    export default {
        components: {
            Card,
            Button
        },
        data() {
            return {
                preferences: {
                    advanced_mode: null,
                    show_preview: null,
                    show_setup_dialog: null
                },
                saving: false
            }
        },
        methods: {
            async setPreferences() {
                this.saving = true
                const result = await this.$store.dispatch('setPreferences', this.preferences)
                this.saving = false
                if (result) {
                    this.$toast.add({severity:'success', summary: 'Success', detail: `Preferences saved.`, life: 2000}); 
                } else {
                    this.$toast.add({severity:'error', summary: 'Error', detail: `Failed to save preferences`, life: 2000}); 

                }
            }
        },
        computed: {
            userPreferences() {
                return this.$store.getters.userPreferences
            }
        },
        watch: {
            userPreferences(newValue) {
                this.preferences = JSON.parse(JSON.stringify(newValue))
            }
        },
        mounted() {
            if (this.$store.getters.userPreferences) {
                this.preferences = JSON.parse(JSON.stringify(this.$store.getters.userPreferences))
            }
        },
    }
</script>

<style scoped>
.userinfo-card {
    height: 31rem !important;
}
.userinfo-card:deep(.p-card-content) {
    min-height: 22rem;
}
.userinfo-card:deep(.p-card-title) {
    display: flex;
    align-items: center;
    font-weight: 400;
}
.userinfo-card:deep(.p-card-footer) {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.preferences-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.preference {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

</style>